import { useState, useCallback, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./Balloon.module.scss";
const cx = classNames.bind(styles);

function Balloon({ onExtend }) {
    const [scale, setScale] = useState(1);
    const [isExtended, setIsExtended] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [particles, setParticles] = useState([]);
    const balloonRef = useRef(null);
    const clickCountRef = useRef(0);

    const createBoomEffect = useCallback(() => {
        const bubbleElement = balloonRef.current?.querySelector(`.${cx("bubble")}`);
        if (bubbleElement) {
            bubbleElement.classList.add(cx("exploding"));
        }

        setTimeout(() => {
            const particleColors = ['#ff4b4b', '#ff9f4b', '#ffff4b', '#4bff4b', '#4b4bff'];
            const newParticles = [];
            
            for (let i = 0; i < 30; i++) {
                const angle = (Math.PI * 2 * i) / 30;
                const velocity = 150 + Math.random() * 150;
                newParticles.push({
                    id: i,
                    x: velocity * Math.cos(angle),
                    y: velocity * Math.sin(angle),
                    color: particleColors[Math.floor(Math.random() * particleColors.length)]
                });
            }
            
            setParticles(newParticles);
        }, 400);

        setTimeout(() => setParticles([]), 1200);
    }, []);

    const handleInteraction = useCallback(() => {
        setScale(0.96);
        clickCountRef.current += 1;
        if (clickCountRef.current === 5) {
            setIsExtended(true);
            onExtend && onExtend(true);
            setTimeout(() => {
                createBoomEffect();
                setTimeout(() => setIsVisible(false), 1200);
            }, 2000);
        }

        setTimeout(() => setScale(1), 200);
    }, [onExtend, createBoomEffect]);

    if (!isVisible && particles.length === 0) return null;

    return (
        <>
            <div className={cx("wrapper")}>
                <div className={cx("balloon")} ref={balloonRef}>
                    <div
                        className={cx("content", { "float-up": isExtended })}
                        style={{ transform: `scale(${scale})` }}
                        onClick={handleInteraction}
                        onTouchStart={handleInteraction}
                    >
                        <div className={cx("bubble")}></div>
                        <div className={cx("string", { extended: isExtended })}></div>
                    </div>
                </div>
            </div>
            {particles.map(particle => (
                <div
                    key={particle.id}
                    className={cx("boom-particle")}
                    style={{
                        top: '50%',
                        left: '50%',
                        backgroundColor: particle.color,
                        '--x': `${particle.x}px`,
                        '--y': `${particle.y}px`
                    }}
                />
            ))}
        </>
    );
}

export default Balloon;
