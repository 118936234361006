import React from 'react';
import classNames from "classnames/bind";
import styles from "./FirstLetter.module.scss";

const cx = classNames.bind(styles);

function FirstLetter() {
    return (
        <div className={cx("content-wrapper")}>
            <div className={cx("content-title")}>Chào Em Bông Hoa Nhỏ Trong Một Khu Vườn To</div>
            <div className={cx("content-tag")}>This content follows the balloon</div>
        </div>
    );
}

export default FirstLetter;
