import React, { useState } from 'react';
import classNames from "classnames/bind";
import styles from "./Home.module.scss";
import Balloon from "../../components/Balloon";
import FirstLetter from "../../components/FirstLetter";

const cx = classNames.bind(styles);

function Home() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false);

    const handleDarkModeToggle = () => {
        setIsDarkMode(!isDarkMode);
    };

    const handleBalloonExtend = () => {
        setIsContentVisible(true);
    };

    return (
        <div className={cx("wrapper", { "dark-mode": isDarkMode })}>
            <Balloon onExtend={handleBalloonExtend} />
            {isContentVisible && <FirstLetter />}
            {!isContentVisible && (
                <div className={cx("button-darkmode")} onClick={handleDarkModeToggle}>
                    <div className={cx("button-content")}>
                        <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
